<template>
  <b-row
      :class="position"
      no-gutters
  >
    <b-col>
      <i @click="$emit('on:star-activate')" v-if="!starValue" style="color: #bbbcc1" class="pointer fa fa-2x fa-star-o"></i>
      <i @click="$emit('on:star-deactivate')" v-else style="color: #F7CB16" class="pointer fa fa-2x fa-star d-bg-amber"></i>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'star-center'
    },
    starValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.star-center {
  text-align: center;
}
.star-left {
  text-align: left;
}
.star-right {
  text-align: right;
}
</style>